import React from 'react'
import './style.scss'

// This component is used on the home page, but there's a copy-paste version of it on the about page. TODO: Add mdx react support when we upgrade this.

const FOBpromo : React.FunctionComponent = () => {
  return (
    <div className="fob-promo d-md-flex justify-content-between align-items-stretch my-4">
        <div className="flex-grow-1 align-self-center" id="fob-base-img">
            <img className="img-responsive" src="/img/fob-promo.svg" alt="An illustration showing the four Qualtim Family of Services company logos: DrJ, CBI, ABTG and Pushing7." />
        </div>

        <div id="fob-promo-text" className="align-self-center flex-shrink-1">
            <p className="cta-text">Learn more about the Qualtim Family of Services and how our expertise can help bring your innovation to market. </p>
            <p className="text-right"><a className="btn btn-primary" href="https://www.qualtim.com">Get Started</a></p>
        </div>
    </div>
  )
}

export default FOBpromo
